import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import mainlogo from '../assets/icon.svg'
import mineLogo from '../assets/logo2.svg'
import audio from '../assets/t.mp3'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import usdtlogo from '../assets/usdt.png'
import vetlogo from '../assets/vet.png'
import openlogo from '../assets/open.png'
import winlogo from '../assets/win.png'


import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import secureAxios from '../secureAxios';
import IBackdrop from './backdrop.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import CopyLink from './Copylink.js';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from 'ethers';

import web3ModalConfig from "../providerOption";

import GridTable from '@nadavshaar/react-grid-table';
import moment, { min } from 'moment/moment';


const DateField = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  return (
      <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
          <span className='rgt-text-truncate' style={{marginLeft: 10, fontSize: '15px'}}>{moment(value).format('DD-MM-YYYY, hh:mm A')}</span>
      </div>
  )
}


const getLogo = {
  usdt: usdtlogo,
  vet: vetlogo,
  open: openlogo,
  win: winlogo
}

const columns = [
  {
    id: 1, 
    field: 'stake_amount', 
    label: 'Stake Amount'
  }, 
  {
    id: 2, 
    field: 'till', 
    label: 'Redemption time',
    cellRenderer: DateField,
  }
]

const row_data = [{
  'stake': 100,
  'tilldate': '2023-05-04'
}]

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  
  
  const data = {
      'amount': 0.0249,
      'price': 21.75,
  }

  const defaultprofileSummary = {
    totalStake: 0,
    withdrawlUSD: 0
  }

  const defaultUser = {
    is_stake: false,
    remain_mine_minute: 60,
    username: 'Default',
    reawardClickamount: 0,
    farmingCoin: 0,
    wallet_balance: 0,
    coins: [
      {'coin_name': 'USDT', 'coin_code': 'USDT', 'balance': 0},
      {'coin_name': 'WIN', 'coin_code': 'WIN', 'balance': 0},
      {'coin_name': 'VET', 'coin_code': 'VET', 'balance': 0},
      {'coin_name': 'OPEN', 'coin_code': 'OPEN', 'balance': 0},
    ]
  }
  
  const profileSummaryTexts = ['My Staking (USDT)', 'Total Withdrawals (USD)']

  function generate(element) {
      return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
          key: value,
        }),
      );
    }
  
    const Demo = styled('div')(({ theme }) => ({
      backgroundColor: 'transparent',
    }));

    const getFormatted = (value) => {
      if (value != undefined){
        console.log(value)
        return value.toFixed(6)
      }
    }
  
  
    const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        borderBottomRightRadius: '40px',
        borderRadius: '5px'
      },
      content: {
        alignItems: 'center',
        display: 'flex'
      },
      title: {
        fontWeight: 'bold',
        color: 'rgb(33, 43, 54)'
      },
      
      icon: {
        height: 32,
        width: 32
      },
      cardBackground: {
        backgroundColor: 'rgb(24, 26, 32)',
        maxWidth: '300px'
        //padding: 2
      },
      progressBar:{
        width: '50%',
        maxWidth: '200px',
        margin: 'auto'
      },
      pan: {
        background: 'rgb(24, 26, 32)'
      }
    }));

    const text = {
        color: "rgb(33, 43, 54)"
    };
  
  const Home = (props) => {
      const { className, tileDilogue, onClicked, ...rest } = props;
      const classes = useStyles();
      const [dense, setDense] = React.useState(false);
      const [secondary, setSecondary] = React.useState(false);
      const [profileSummary, setprofileSummary] = React.useState(defaultprofileSummary);
      const [userDetails, setUserDetails] = React.useState(defaultUser);
      const [userRemainMine, setuserRemainMine] = React.useState(0);
      const [ dialogVisible, setdialogVisible ] = React.useState(false);
      const [time, setTime] = React.useState(Date.now());
      const [open, setOpen] = React.useState(false);

      const handleClose = () => {
        setdialogVisible(false)
      };

      const mine = async () => {
        const provider = await web3ModalConfig.connect();
        const library = new ethers.providers.Web3Provider(provider);
        const accounts = await library.listAccounts();
        const network = await library.getNetwork();
    
        if (network?.chainId !== 56){
          alert("Wrong network selected.")
          return;
        }
        mineProcees()
      };

      const Profiledetails = () => {
        setOpen(true);
        secureAxios
            .get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/userinfo`).then(response => {
              setOpen(false);
              if (response.data?.user){
                setUserDetails(response.data?.user);
                setuserRemainMine(response.data?.user?.remain_mine_minute);
              }
              if (response.data?.profileSummary){
                setprofileSummary(response.data?.profileSummary);
              }
            }).catch(error => {
              setOpen(false);
              
    
            })
      }

      const share = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null

      }

      const get_time = () => {
        const totsec = userRemainMine * 60;
        const h = totsec/3600
        const m = (totsec%3600)/60
        const sec =(totsec%3600)%60
        return `${parseInt(h)} hours and ${parseFloat(m).toFixed(0)} mintutes Remaining`
      }

      const mineProcees = () => {
        if (userRemainMine > 0 && userDetails.is_stake){
          return false
        }
        setOpen(true);
        secureAxios
            .post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/create_mine`).then(response => {
              setOpen(false);
              setuserRemainMine(60);
              alert("Farmed Successfully");
              Profiledetails();
              playSound()
            }).catch(error => {
              setOpen(false);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
    
            })

      }

      React.useEffect(() => {
        const intervalId = setInterval(() => {
          if (userDetails?.is_stake){
            setuserRemainMine(oldValue => {return oldValue - 1 < 0 ? 0 : oldValue - 1})
          }

        }, 1000*60)
      
        return () => clearInterval(intervalId); //This is important
       
      }, [userDetails])

      const playSound = () => {
        const audio = new Audio('https://cdn.pixabay.com/download/audio/2022/03/25/audio_2ef995da24.mp3?filename=minitractor-small-tractor-106362.mp3'); // Path to your local audio file
        audio.play();
      };


      React.useEffect(() => {
        Profiledetails()
        return
      }, []);


      return (
      <>
        <IBackdrop isOpen={open} msg={"Signin progress"}/>

       <Paper
       className={classes.pan}
       style={{background: 'rgb(24, 26, 32)'}}
        sx={{
          p: 2,
          margin: 'auto',
          maxWidth: 500,
          flexGrow: 1
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Demo>
                  {Object.keys(userDetails?.coins).length > 0 &&
                    <List dense={dense}>
                        {userDetails?.coins.map((item) => {
                          return <ListItem
                              secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                  <AccountBalanceWalletOutlinedIcon style={{color: 'rgb(33, 43, 54)'}}/>
                              </IconButton>
                              }
                          >
                              <ListItemAvatar>
                              <Avatar style={{background: 'transparent'}}>
                                  <img style={{width: 40}} src={getLogo[item.coin_code.toLocaleLowerCase()]} alt="fireSpot"/>
                              </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                              className={classes.title}
                              primary={getFormatted(item?.balance)  + ' ' + item.coin_code}
                              primaryTypographyProps={{ style: {fontWeight: 'bold'} }}
                              secondary={secondary ? 'Secondary text' : null}
                              />
                          </ListItem>
                        })}
                        

                        <ListItem
                            // secondaryAction={
                            // <IconButton onClick={() =>setdialogVisible(true)} edge="end" aria-label="delete">
                            //     <GroupAddIcon style={{color: 'rgb(33, 43, 54)'}} />
                            // </IconButton>
                            // }
                        >
                            <ListItemAvatar>
                            <Avatar style={{background: 'transparent'}}>
                                <PaidOutlinedIcon style={{color: 'rgb(33, 43, 54)'}}/>
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                            className={classes.title}
                            primary={profileSummary?.totalStake * 5000}
                            secondary={<><Typography variant="subtitle2">Farming Coin</Typography>
                              <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 10, cursor: 'pointer'}} variant="subtitle2">This coin was received from a free giveaway.</Typography>
                              </>}
                            primaryTypographyProps={{ style: {fontWeight: 'bold'} }}
                            secondaryTypographyProps={{style: text}}
                            />
                        </ListItem>
                        
                    </List>
                }
                </Demo>

                <CopyLink userDetails={userDetails} />

              </Grid>
            </Grid>
            
          </Grid>
          
        </Grid>
      </Paper>

      <Paper
       className={classes.pan}
       style={{background: 'rgb(24, 26, 32)'}}
        sx={{
          p: 2,
          margin: 'auto',
          maxWidth: 500,
          flexGrow: 1,
          marginTop: '20px'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
            
              {userDetails?.modeOfMint != 2 && <div className={classes.progressBar} onClick={mine}>
                <CircularProgressbarWithChildren 
                styles={buildStyles({
                  trailColor: userRemainMine > 0 ? "#fff" :  "#4BB543" ,
                  pathColor: userRemainMine > 0 ? "rgb(252, 213, 53)" :  "#4BB543",
                  cursor: 'pointer'
                }
                )}
                value={240 - userRemainMine}
                maxValue={240}
                strokeWidth={2}>
                  <>
                  <Avatar style={{background: 'transparent', position: 'relative', overflow: 'hidden',}} sx={{ width: 50, height: 50 }}>
                      <img style={{width: 70, position: 'absolute', animation: userRemainMine > 0 ? 'moveCar 5s linear infinite': ''}} src={mineLogo} alt="mine"/>                      
                  </Avatar>
                  <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 12, cursor: 'pointer'}} variant="subtitle2">{userRemainMine > 0 && userDetails?.is_stake ? 'Farming in progress' : 'Click to Farm'}</Typography>
                  <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 9, marginTop: '10px'}} variant="subtitle2">{userRemainMine > 0 && userDetails?.is_stake &&  get_time()}</Typography>
                  </>
                  
                </CircularProgressbarWithChildren></div>}
                
                {userDetails?.modeOfMint == 2 && <div style={{width: '100%'}}>
                    <GridTable style={{width: '100%'}} width={'200px'} columns={columns} isPaginated={false} showSearch={false} showRowsInformation={false} showColumnVisibilityManager={false} rows={userDetails?.stake_data}/>
                </div>}
              
              <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 9, marginTop: '10px'}} variant="subtitle2">Your address is </Typography>
              <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 10, cursor: 'pointer'}} variant="subtitle2">{userDetails.address}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Paper
       className={classes.pan}
       style={{background: 'rgb(24, 26, 32)'}}
        sx={{
          p: 2,
          margin: 'auto',
          maxWidth: 500,
          flexGrow: 1,
          marginTop: '20px',
          marginBottom: '70px'
        }}
      >
        
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button style={{
              borderRadius: 35,
              backgroundColor: "transparent",
              color: 'rgb(33, 43, 54)',
              fontSize: "14px",
              border: '0.5px solid rgb(33, 43, 54)',
              marginBottom: '10px'
          }} variant="contained"  onClick={()=>alert("Your username is "+ userDetails?.username)} endIcon={<RemoveRedEyeIcon />}>
            Username
          </Button>
        </Stack>
        <Grid container spacing={2} style={{paddingTop: 10, paddingBottom: 0}}>
        <React.Fragment>
        {Object.keys(profileSummary).map((item, i) => (
            <Grid key={i} item xs={6} lg={3} xl={2} style={{paddingTop: 2}}>
                <List dense={dense} style={{paddingTop: 0, paddingBottom: 0}}>                      
                  <ListItem style={{paddingTop: 0, paddingBottom: 0}}>
                      <ListItemText
                      className={classes.title}
                      primary={profileSummaryTexts[i]}
                      secondary={profileSummary[item]}
                      primaryTypographyProps={{style: {fontWeight: 600, fontSize: 12, textAlign: 'center'}}}
                      secondaryTypographyProps={{style: {color: 'rgb(33, 43, 54)', marginTop: 5, fontSize: 11, textAlign: 'center'}}}
                      />
                  </ListItem>
                  </List>
            </Grid>
          ))}
          </React.Fragment>
        </Grid>
      </Paper>


      <Dialog onClose={handleClose} open={dialogVisible}>
      <DialogTitle className='no_data_text'>Share Referral Link</DialogTitle>

      <List sx={{ pt: 0 }}>
        {/* <ListItem key={1} style={{cursor: 'pointer'}} onClick={(event) => share('https://wa.me/?text=Usdbooster Is The Decentralized Platform On The Binance Smart Chain Network \n https://usdbooster.com/signup?u='+userDetails.username)}>
          <ListItemAvatar>
            <WhatsAppIcon style={{color: 'rgb(37, 211, 102)'}}>
            </WhatsAppIcon>
          </ListItemAvatar>
          <ListItemText primary={"Share on Whatsapp"} />
        </ListItem> */}
        
        <CopyToClipboard className='no_data_text' style={{cursor: 'pointer'}} text={'https://dexfarming.com/signup?u='+userDetails.username} onCopy={() => alert('Copied Successfully')}>
          <ListItem key={2}>
            <ListItemAvatar>
              <ContentCopyIcon>
              </ContentCopyIcon>
            </ListItemAvatar>
            <ListItemText primary={"Click to copy"} />
          </ListItem>
        </CopyToClipboard>
      </List>
    </Dialog>

      </>
      
      )
  }
  
  export default React.memo(Home)

